@import './variables';

nav {
    position: absolute;
    z-index: 16695055;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);

    transition: transform 0.8s;

    &.enabled {
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
    }
}

.mobile-nav {
    width: 100%;

    display: flex;
    justify-content: flex-end;

    padding: 20px;
    transition: transform 1s;

    @include respond-to("small") {
        display: none;
    }



    .toggle {
        background: $font-color;
        border-radius: 50%;
        padding: 10px;
        width: fit-content;
        box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
        border: none;
        z-index: 101;
        cursor: pointer;

        svg {
            color: $background;
            fill: $background;
            font-size: 32px;
        }
    }

    .mobile-menu {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: $font-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        z-index: 99;
        transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        transition: transform 0.5s;
        font-size: 22px;

        a {
            color: $background;

            &:hover {
                cursor: pointer;
            }
        }

        &.active {
            transform: translateY(0%);
            -webkit-transform: translateY(0%);
        }
    }
}

.desktop-nav {
    display: none;
    width: 100%;
    
    
    justify-content: center;
    align-items: center;
    
    
    @include respond-to("small") {
        display: flex;
    }
    
    .desktop-menu {
        width: fit-content;
        background: $font-color;
        border-radius: 64px;
        padding: 10px 20px;
        margin-top: 18px;

        box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;

        a {
            font-size: 36px;
            color: $background;
            margin: 0px 12px;
            transition: .5s;
            &:hover {
                cursor: pointer;
                font-size: 48px;
                color: $primary;
            }
        }
    }
}