@import './variables';


.img-events-container {
    position: relative;
    margin: 0px 5%;

    @include respond-to("small") {
        margin: 0px 13%;
    }

    @include respond-to("large") {
        margin: 0px 25%;
    }

    img {
        width: 100%;
        border-bottom-left-radius: 64px;
        border-bottom-right-radius: 64px;
    }

    .event-overlay {
        position: absolute;
        top: 25.52%;
        right: 0;
        height: 10px;
        width: 22.5%;
        height: 47.56%;

        transition: .5s;

        &:hover {
            cursor: pointer;
            -webkit-box-shadow: 0px 0px 160px 39px rgba(255, 79, 40, 0.74);
            -moz-box-shadow: 0px 0px 160px 39px rgba(255, 79, 40, 0.74);
            box-shadow: 0px 0px 160px 39px rgba(255, 79, 40, 0.74);
        }
    }

    .event-overlay-1 {
        left: 11.64%;
    }

    .event-overlay-2 {
        left: 37.25%;
    }

    .event-overlay-3 {
        left: 63.56%;
    }
}