@import './_variables';

.grain-tile-column {
    padding: 0;
}

.grain-tile {
    aspect-ratio: 1 / 1;
    position: relative;
    z-index: 10;


    .title {
        padding-left: 40px;

        @include respond-to("small") {
            padding-left: 50px;
        }

        @include respond-to("large") {
            font-size: 212px;
            padding-top: 64px;

            &.bottom {
                padding-top: 0px;
                padding-bottom: 64px;
            }
        }
    }

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: $primary;
        opacity: 0;
        transition: 1s;
    }

    &:hover {

        .bg {
            opacity: 1;
        }

        .overlay {
            opacity: 1;
            z-index: 5;

            @include respond-to("small") {
                z-index: unset;
            }
        }

        .title {

            .name,
            .number {
                z-index: 10;
            }
        }
    }
}

.chapter-name {
    font-size: .3em;
    vertical-align: middle;
}

.title-bottom {
    display: flex;
    align-items: flex-end;
}

.overlay {
    position: relative;
    display: flex;
    align-items: center;

    pointer-events: none;
    box-sizing: content-box;
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    padding: 20px;

    color: $font-color;
    background: $primary;

    font-size: 20px;
    text-align: center;

    transition: 1s;
    opacity: 0;

    overflow-y: scroll;
    z-index: -10;

    @include respond-to("small") {
        position: unset;
        box-sizing: border-box;

        overflow-y: hidden;
        left: 100%;
    }

    &.text-opposite {
        @include respond-to("small") {
            left: -100%;
        }
    }

    .text {
        padding-right: 2em;

        @include respond-to("small") {
            padding: 40px;
            font-size: clamp(1.2rem, 1.7vw, 2rem);
        }

        @include respond-to("large") {
            font-size: 64px;
        }

        &.text-top {
            margin-top: 0px;
        }
    }

}

.img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        position: absolute;
        top: 0;
        width: 90%;
        padding-top: 30px;

        &.image-top {
            bottom: 0;
            left: 0;
        }
    }
}

.title {
    font-family: 'pn_new_edgelight';
    font-weight: 100;
    line-height: 50px;
    text-align: left;

    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin: 0;

    font-size: 54px;

    @include respond-to("small") {
        font-size: clamp(54px, 112px, 120px);
        line-height: 120px;
        padding-top: 25px;
    }

    &.smaller-title {
    margin-top: 1em;
    font-size: 3em;
    line-height: 40px;

    @include respond-to("small") {
        line-height: 80px; 
        font-size: 82px;
    margin-top: 0em;
    padding-top: 0em;

    }
    
}
}

.quote-container {
    background: $primary;
    border-radius: 64px;

    padding: 20px 0px;

    margin: 90px 20px;


    @include respond-to("small") {
        padding: 50px 40px;
        margin: 20%;
        line-height: 55px;
    }

    blockquote {
        font-size: clamp(1.8rem, 2vw, 4.5rem);
        text-align: center;
        margin-bottom: 0px;
        font-weight: bold;

        @include respond-to("small") {
            font-size: 46px;
        }
    }

    .author {
        margin-top: 0px;
        text-align: center;
        font-size: 22px;
        font-weight: normal;

        @include respond-to("small") {
            font-size: 28px;
        }
    }
}