@import './_variables';

.carousel-text {
    position: absolute;
    z-index: 30;
    text-align: center;

    padding: 40px;

    .button {
        color: $font-color;
    }
}

.carousel-text-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 30;
}