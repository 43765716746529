$background: #0D0D0D;
$primary: #FF5028;

$font-color: #CCCCCC;

// BREAKPOINTS
$breakpoints: (
  'small':  ( min-width:  1023px ),
  'medium': ( min-width:  992px ),
  'large':  ( min-width: 2100px )
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {

    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {

        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}