@import './variables';

.border-container {
    position: relative;
    border-bottom: 20px $primary solid;
    border-top: 20px $primary solid;

}

.video-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background-image: url('../assets/img/thumb.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    z-index: 1;

    &:hover {
        cursor: pointer;
    }
}
