@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/grid/columns.sass";
@import 'bulma/sass/helpers/_all.sass';

@import './styles/variables';
@import '../node_modules/locomotive-scroll/dist/locomotive-scroll.min.css';

@font-face {
  font-family: 'pn_new_edgelight';
  src: url('./assets/fonts/PNNewEdge/pnnewedge-light-webfont.woff2') format('woff2'),
    url('./assets/fonts/PNNewEdge/pnnewedge-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pn_new_edgeregular';
  src: url('./assets/fonts/PNNewEdge/pnnewedge-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/PNNewEdge/pnnewedge-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  color: $font-color;
  box-sizing: border-box;
}

html,
body {
  background: $background;
}

body {
  margin: 0;
  font-family: 'pn_new_edgeregular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

App {
  height: 100vh;
}

#root {
  height: 100vh;

}

canvas {
  cursor: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

::-moz-selection {
  /* Code for Firefox */
  color: $font-color;
  background: $primary;
}

::selection {
  color: $font-color;
  background: $primary;
}


.modal-test {
  opacity: 1;
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 110;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background: rgba(255, 255, 255, 0.19);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);

  transition: transform .5s;

  transform: translateX(-100%);

  pointer-events: none;

  @include respond-to("small") {
    opacity: 0;
    padding-top: 5%;
    transform: translateX(0%);

    transition: opacity .5s;


    &.active {
      opacity: 1;
      pointer-events: all;
      z-index: 100000000;
    }

  }

  &.active {
    transform: translateX(0%);
    pointer-events: all;
    z-index: 100000000;
  }

}

/* Modal Content/Box */
.modal-content {
  background-color: $primary;
  color: $background !important;
  margin: 0% auto;
  /* 15% from the top and centered */
  padding: 20px;
  width: 100%;
  /* Could be more or less, depending on screen size */
  height: 100%;
  overflow: auto;
  padding-top: 30px;
  padding-bottom: 80px;

  display: flex;
  flex-direction: column;

  font-size: 18px;
  align-items: center;

  @include respond-to("small") {
    border-radius: 64px;
    height: unset;
    width: 40%;
    max-width: 1200px;
    padding: 40px;
    align-items: center;
  }

}

.gray-modal {
  background: $font-color;

  p {
    color: $background !important;

    a {
      color: $background !important;
    }
  }
}

/* The Close Button */
.close {
  align-self: flex-end;
  color: $background;
  font-size: 36px;
  font-weight: bold;
  transition: transform .5s;
  cursor: pointer;
}

.close:hover,
.close:focus {
  transform: scale(1.05);
}

.columns {
  margin-top: 0px !important;
}