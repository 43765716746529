@import './variables';

.content {
    font-size: 18px;

    @include respond-to("small") {
        font-size: 30px;
    }

    @include respond-to("large") {
        font-size: 32px;
    }
}
