@import './_variables';

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0px;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-height: 100vh;

    z-index: 16695056;

    background-color: $primary;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;

    padding: 5%;
    padding-top: 2%;

    * {
        &::-moz-selection {
            /* Code for Firefox */
            color: $primary;
            background: $font-color;
        }

        &::selection {
            color: $primary;
            background: $font-color;
        }
    }

    @include respond-to("small") {
        padding-top: 0%;
        max-width: 1200px;

    }

    .close-icon {
        font-size: 48px;
        align-self: flex-end;

        &:hover {
            cursor: pointer;
        }

        @include respond-to("small") {
            font-size: 64px;
            margin-top: 1%;
        }
    }


    h1 {
        font-family: 'pn_new_edgelight', sans-serif;
        font-size: 40px;
        font-weight: 100;
        text-align: center;
        color: $font-color;
        margin-bottom: 0em;

        @include respond-to("small") {
            font-size: 10rem;
            margin-top: 0.4em;
            line-height: 160px;
        }

    }

    h2 {
        text-align: center;
        font-weight: 100;
        font-size: 14px;

        margin-top: 0em;

        @include respond-to("small") {
            font-size: 38px;
        }
    }

    p {
        text-align: left;
        font-weight: 100;
        padding: 0px 40px;

        @include respond-to("small") {
            font-size: 26px;
            padding: 0px 20%;
        }
    }

    .modal-frame {
        filter: grayscale(100%);
    }
}

.grain-modal {
    transform: translateX(-100%);
    transition: .5s;
    overflow-y: auto;
    padding-bottom: 92px;

    background: $font-color;
    color: $background;

    @include respond-to("small") {
        height: unset;
        margin-top: 50px;
        border-radius: 64px;

        transform: translateX(0%) scale(0);
        width: 40%;
        max-height: 95vh;
    }

    p {
        @include respond-to("small") {
            margin-top: 0px;
            padding: 0px;
        }
    }

    .grain-img-container {

        img {
            width: 100%;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
        }
    }

    &.active {
        transform: translateX(0%);

        @include respond-to("small") {
            margin: 100px;
            margin-top: 20px;
            transform: scale(1);
        }

    }
}

.grain-img-container {
    width: 300px;

    img {
        width: 100%;
        box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    }
}


.modal-form {
    display: flex;
    flex-direction: column;
}


input {
    border-radius: 64px;
    font-size: 18px;

    padding: 5px 10px;
    margin-top: 5px;

    border: none;

    color: $background;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}

form {
    color: $background !important;

    display: flex;
    flex-direction: column;
    gap: 10px;

    padding: 15px;
    border: 1px $background solid;
    border-radius: 6px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;

    @include respond-to("small") {
        padding: 10px calc(40px + 5%);
    }
}

.buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;

    @include respond-to("small") {
        margin-top: 2em;
    }
}

.radio-buttons {
    display: flex;
    justify-content: center;

    @include respond-to("small") {
        margin-bottom: 1em;
    }
}

.modal-bg {
    display: none;
    position: absolute;
    top: 0;
    z-index: 16695056;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.19);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);

    transition: backdrop-filter 1s;

    pointer-events: none;
    opacity: 0;

    @include respond-to("small") {
        display: block;
    }

    &.active {
        pointer-events: all;
        backdrop-filter: blur(15.1px);
        -webkit-backdrop-filter: blur(15.1px);
        opacity: 1;
    }

    &.blur-mobile {
        display: block;

    }
}

.redirect-modal {
    background: $font-color;
    transform: translateX(-100%);
    transition: .5s;

    * {
        color: $background;

    }

    @include respond-to("small") {
        width: unset;
        height: unset;
        margin-top: 50px;
        border-radius: 64px;

        transform: translateX(0%) scale(0);
        width: 60vw;
        max-height: 95vh;
    }

    p {
        text-align: center;

        @include respond-to("small") {
            margin-top: 0px;
            padding: 0px;
        }
    }

    .title {
        font-family: 'pn_new_edgeregular', sans-serif;
        color: $background !important;

        @include respond-to("small") {
            font-size: 6em;
            margin: 0.4em 0px;
            line-height: 90px;
            margin-top: 0em;
        }
    }

    .grain-img-container {
        width: 300px;

        img {
            width: 100%;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
        }
    }

    &.active {
        transform: translateX(0%);

        @include respond-to("small") {
            margin: 100px;
            margin-top: 20px;
            transform: scale(1);

        }

    }
}

//  Radio
$borderWidth: 2px;

input[type=radio] {
    position: absolute;
    visibility: hidden;
    display: none;
}

.label {
    color: $background;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 20px;
    font-size: 16px !important;
}

input[type=radio]:checked+.label {
    color: $font-color;
    background: $primary;
}

.label+input[type=radio]+.label {
    border-left: solid $borderWidth $background;
}

.radio-group {
    border: solid $borderWidth $font-color;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
}

label {
    color: $background !important;
    font-size: 18px;

    a {
        color: $background !important;
        text-decoration: underline;
    }
}

.input-container {
    display: flex;
    flex-direction: column;
}

.skip-label {
    text-decoration: underline;
    margin-bottom: 1em;
    color: $background !important;
    text-align: center;

    &:hover {
        cursor: pointer;
    }

    @include respond-to("small") {
        margin-bottom: 0px;
    }
}

.error-message {
    display: block;
    color: $background !important;

    svg {
        fill: $background;
    }
}

// Intro modal
.intro-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 35vh;
    background: $primary;
    padding: 20px;
    border-radius: 32px;
    margin: 0px 20px;

    * {

        &::-moz-selection {
            /* Code for Firefox */
            color: $primary;
            background: $font-color;
        }

        &::selection {
            color: $primary;
            background: $font-color;
        }
    }


    @include respond-to("small") {
        width: 678px;
        margin: unset;
        border-radius: 64px;
    }


    blockquote {
        font-size: 1.3em;
        text-align: center;
        font-weight: 100;

        @include respond-to("small") {
            font-size: 1.6em;
        }
    }

    .author {
        margin-top: 0px;
        text-align: right;
        font-size: 16px;
        font-weight: normal;

        @include respond-to("small") {
            font-size: 18px;
        }
    }
}

.intro-modal-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 16695056;
}

.intro-logo-container {
    position: absolute;
    z-index: 100000000;
    width: 100%;
    text-align: center;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;

    .logo {
        background: $primary;
        width: 100%;

        margin: 0px;
        font-family: 'pn_new_edgelight', sans-serif;
        font-size: 32px;
        font-weight: 100;

        padding: 0px 30px;

        @include respond-to("small") {
            font-size: clamp(54px, 72px, 110px);
            width: fit-content;

        }
    }

    .subtitle {
        background: $primary;
        border-bottom-left-radius: 58px;
        border-bottom-right-radius: 58px;
        width: fit-content;
        margin: 0px;
        padding: 0px 30px;
        padding-bottom: 10px;
        font-size: 12px;


        @include respond-to("small") {
            font-size: 16px;
            width: fit-content;

            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;

        }
    }
}

.radio-custom {
    position: relative !important;
    display: inline !important;
    visibility: visible !important;
}