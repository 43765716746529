@import './variables';

.button {
    margin-top: 2em;
    font-size: 24px;
    color: $font-color;
    background: $primary;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    border: none;
    padding: 10px 70px;

    border-radius: 64px;
    transition: 0.2s;

    &:active {
        color: $primary;
        background: $font-color;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    &.inversed {
        color: $primary;
        background: $font-color;
    }
}


.grid {
    display: grid;
    grid-template-columns: repeat(43, 45px);
    grid-template-rows: repeat(24, 45px);
    gap: 0px 0px;
}

.grid-tile {
    opacity: 0;

    img {
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        box-sizing: border-box;
    }

    &:hover {
        opacity: 1;
        border-color: $primary;
        box-shadow: 0 0 20px $primary;
        cursor: pointer;
    }

    &.is-issued {
        opacity: 1;
        z-index: 5;

        &:hover {
            cursor: auto;
            box-shadow: none;
        }
    }

}

.overflow-container {
    width: 100%;
    overflow-x: auto;
    background: black;

    border-bottom: 50px black solid;

    @include respond-to("small") {
        display: flex;
        justify-content: center;
    }
}


.overflow-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
}

.overflow-container::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
}

.overflow-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, .3);
    background-color: $primary;
    border: 1px solid $font-color;
}

.shadow-horizontal {
    display: none;
    pointer-events: none;
    position: absolute;

    @include respond-to("small") {
        display: unset;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
    }
}

.shadow-vertical {
    display: none;
    pointer-events: none;
    position: absolute;

    @include respond-to("small") {
        display: unset;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 97%, rgba(0, 0, 0, 1) 100%);
    }
}

.frame {
    position: absolute;
    z-index: 100;
    pointer-events: none !important;
    width: 100%;

    @include respond-to("small") {
        top: -12%;
        z-index: 16695057;
    }

    .frame-right {
        float: right;
    }

    img {
        width: 5%;

        @include respond-to("small") {
            width: unset;
        }
    }
}

.is-absolute {
    position: absolute;
}

.primary-divider {
    position: relative;
    top: -5px;
    height: 20px;
    width: 100%;
    background: $primary;
    margin-bottom: 20px;
}

.image-contain {
    background-size: cover;
    min-height: 50vh;
}

.section-text {
    background-color: $font-color;
    border-radius: 32px;
    padding: 40px;
    margin: 10% 5%;

    @include respond-to("small") {
        margin: 5% 13%;
        padding: 40px 50px;
        padding-right: 7%;
        border-radius: 64px;
    }

    @include respond-to("large") {
        margin: 3% 25%;
        padding-right: 5%;
    }

    &.use-rows {
        @include respond-to("small") {
            padding: 40px 8%;
        }
    }

    &.no-title {
        padding-left: 10%;
    }



    * {
        color: $background;
    }

    &.section-flow {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 0px;
    }
}

.section-partners {
    padding-bottom: 80px;

    @include respond-to("small") {
        padding-right: 50px;
    }
}

.partner-columns {

    .column {
        img {
            width: 100%;
        }
    }

    @include respond-to("small") {
        padding-left: 80px;
        padding-right: 80px;
    }

}

.align-center-container {
    height: 100%;
    align-items: center;
    display: flex;

    .button {
        margin-top: 0px;
    }
}

.justify-center-container {
    display: flex;
    justify-content: center;
}

.invisible {
    opacity: 0;
}

.sticky-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;

    z-index: 10;

    transform: translateY(150%);
    -webkit-transform: translateY(150%);
    transition: 0.5s;

    &.enabled {
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
    }

}

.logo-container {
    background-color: $primary;
    color: $font-color;
    width: fit-content;
    padding: 0px 30px;
    padding-top: 10px;

    border-radius: 64px;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;

    transition: transform .5s;

    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }


    .logo {
        font-family: 'pn_new_edgelight', sans-serif;
        font-size: 32px;
        font-weight: 100;
        text-align: center;

        margin: 0px;

        @include respond-to("small") {
            font-size: 56px;
        }
    }
}

p {
    a {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }
}

.margin-bottom-2 {
    margin-bottom: 2em;
}

.section-carousel {
    margin-top: 5%;

    .marquee {
        display: flex;
        gap: 30px;
    }
}

.quote {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.width-100 {
    width: 100%;
}

.medium-font {
    font-size: 1.5em;

    @include respond-to("small") {
        font-size: 1.5em;

    }
}

section {
    position: relative;
}

.embedded-image {
    width: 90%;

    @include respond-to("small") {
        width: 50%;
        margin: 1em 0em;
    }
}

.credit-column {
    text-align: center;
    transition: .5s;

    @include respond-to("small") {
        h2 {
            // min-height: 80px;
        }
    }

    &:hover {
        background: $primary;
        cursor: pointer;
        * {
            color: $font-color !important;
        }
    }
}