@import './_variables';

footer {
    background: $primary;

    @include respond-to("small") {
        padding: 1rem 13%;
    }

    @include respond-to("large") {
        padding: 1rem 25%;
    }

    * {
        &::-moz-selection {
            /* Code for Firefox */
            color: $primary;
            background: $font-color;
        }

        &::selection {
            color: $primary;
            background: $font-color;
        }
    }
}

.footer-column {
    font-size: 22px;

}